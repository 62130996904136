import { createTheme } from "@mui/material/styles";

const primary = {
  300: "#7AB5C6",
  400: "#62AABA",
  500: "#0281A8",
  600: "#3FABD2",
};
const secondary = {
  100: "#ff6b6b",
  400: "#ff6b6b",
  500: "#ff6b6b",
  600: "#ff6b6b",
};

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      light: primary[400],
      main: primary[500],
      dark: primary[600],
    },
    secondary: {
      light: secondary[400],
      main: secondary[500],
      dark: secondary[600],
    },
    text: {
      primary: "#333333",
    },
    info: {
      light: "#fff",
      main: "#fff",
      dark: "#fff",
    },
  },
  color: {
    primary: primary,
    secondary: secondary,
    white: "#fff",
    cream: "#F3F2F1",
    gray: {
      100: "#f3f2f0",
      200: "#E1E1E1",
      300: "#c5c5c5",
      400: "#939291",
      500: "#8a8a8a",
      600: "#626262",
      700: "#333333",
    },
    black: "#3C3C3C",
  },
  typography: {
    fontFamily: "Lato",
    body1: {
      lineHeight: 1.3,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          boxShadow: "none",
          borderRadius: "2px",
          fontWeight: 700,
        },
      },
    },
  },
});

export default theme;
