import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  siteLanguage: "de",
  language: "de",
};

export const i18nSlice = createSlice({
  name: "i18n",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setSiteLanguage: (state, action) => {
      state.siteLanguage = action.payload;
    },
  },
});

export const { setLanguage, setSiteLanguage } = i18nSlice.actions;

export default i18nSlice.reducer;
